import tw, { styled } from 'twin.macro';
import { Footer } from '../../components/footer';
import Layout from '../../components/Layout';
import { Navigation } from '../../components/Navigation';

const Container = styled.div`
  ${tw`container w-11/12 md:w-3/4 mx-auto mt-10 py-2 px-3 pr-4 md:py-12 md:px-14 md:pr-16 lg:py-20 lg:px-24 lg:pr-32 h-3/4 bg-gray-100 rounded-xl overflow-scroll overflow-x-hidden`}
`;

function PrivacyPolicy() {
  return (
    <Layout tw="font-body flex flex-col overflow-x-hidden md:overflow-x-visible">
      <Navigation />
      <h2 tw="flex self-center mt-32 text-black text-center text-3xl md:text-6xl font-bold">
        Privacy Policy
      </h2>
      <Container className="example">
        <p tw="leading-7 text-sm md:text-sm xl:text-lg">
          <h1 tw="font-semibold pb-4 text-xl uppercase">
            DATA PROTECTION POLICY
          </h1>
          <p tw="pb-4 text-sm">
            This Privacy Policy (hereinafter: “Policy”) describes the ways we
            collect, store, use and manage any Personal Data and Anonymous Data
            (hereinafter: “Information”) you provide to us, or we collect in
            connection with your use of Bidda Launchpad website accessible at
            www.bidda.io and our other websites on which this Policy
            appears(hereinafter: the Site).By using the Site you agree to this
            Data Protection Policy and the processing of your data, including
            your personal information, in the manner provided in this Policy. If
            you do not agree to the terms of this Policy, please do not use the
            Site.
          </p>
          <p>
            1. <strong>Personal Data</strong>
          </p>
          <p tw="pb-4 text-sm">
            For purposes that will be mentioned further in this Policy we may
            process and/or collect the following information that can be used to
            identify you as an individual (hereinafter:“Personal Data”):
          </p>
          <p>
            2. <strong>Email address</strong>
          </p>
          <p tw="pb-4 text-sm">
            You may explicitly decline to submit Personal Data through the Site,
            in which case we will not be able to provide to you our newsletters
            and other updates via email.
          </p>
          <p>
            3. <strong>Anonymous Data Collected Automatically</strong>
          </p>
          <p tw="text-sm">
            We collect non-personally identifiable information that alone or in
            combination with similar information can not be used to identify you
            (hereinafter: “Anonymous Data”) from your computer, mobile device or
            browser when you use the Site as a logged in user. The Site
            automatically collects usage information, such as:
          </p>
          <p tw="pb-4 text-sm">
            a) Your IP address
            <br />
            b) The type of device you use
            <br />
            c) Browser type, version and language
            <br />
            d) Type and version of your operating system
            <br />
            e) Your activity on the Site
            <br />
            f) The number and frequency of visitors to the SiteWe may collect
            Anonymous Data or use third-party services for that, for example,
            web analytics services. This Anonymous Data is not linked to
            Personal Data.
          </p>
          <p>
            4. <strong>Tracking Technologies We Use</strong>
          </p>
          <p tw="pb-4 text-sm">
            We do not use cookies and other tracking technologies.
          </p>
          <p>
            5. <strong>Third-Parties Tracking Technologies</strong>
          </p>
          <p tw="pb-4 text-sm">
            This Policy does not cover the use of cookies and other similar
            technologies by third parties. For example, we may use third-party
            analytic services to collect Information about use of the Site or
            the Site may contain links leading to other sites. We do not control
            when or how third parties place cookies on your computer. For
            example, third-party websites to which a link points may set cookies
            on your computer or analytic services may do the same.These cookies
            or other similar technologies may be used to track your behavior on
            other websites, and we have no control over this.
          </p>
          <p>
            6. <strong>Why We Collect Information</strong>
          </p>
          <p tw="pb-4 text-sm">
            The Information is used for:
            <br />
            a) Delivering you the Site and its functionality
            <br />
            b) Your seamless interaction with the Site
            <br />
            c) Analytical and statistical purposes
            <br />
            d) Understanding your interaction with the Site
            <br />
            e) Improvement and development of the Site or our other services and
            products
            <br />
            f) Enforcement of this Policy and the Terms
            <br />
            g) Sending you related information, including newsletters, special
            offers, confirmations, updates and security alerts
            <br />
          </p>
          <p>
            7. <strong>Duration of Retaining of the Information</strong>
          </p>
          <p tw="pb-4 text-sm">
            We will keep your Information for as long as you are a user and,
            thereafter, for no longer than is reasonably necessary for reporting
            and reconciliation purposes pursuant to legal requirements.
          </p>
          <p>
            8. <strong>Your Consent</strong>
          </p>
          <p tw="text-sm pb-4">
            You expressly consent to our processing, collection, transfer,
            storage, disclosure and other uses of your Information as described
            in this Policy and the Terms of Use at instance when you provide
            information to us through the Site and/or accept this Policy. In
            addition, through this use and interactions with the Site you
            specifically consent to:
            <br />
            a) Our collection of information by automated means and to our
            subsequent processing and using of information in accordance with
            this Policy
            <br />
            b) The storage and transfer of information to locations wherever we
            and our service providers have facilities.
            <br />
          </p>
          <p tw="pb-4 text-sm">
            The site and Bidda users accept the data protection policy with the
            allowance for the personal data and anonymous data – the full users
            declaration statement:“I hereby grant my consent for my personal
            data (e-mail address, name, surname and index number) in connection
            with Article 6(1)(a) of Regulation (EU) 2016/679 of theEuropean
            Parliament and of the Council of 27 April 2016 on the protection of
            individuals with regard to the processing of personal data and on
            the free movement of such data and repealing Directive 95/46/EC
            (RoD). I can withdraw my consent at any time by unsubscribing from
            the newsletter. The administrator of personal data is the Bidda S.p.
            z.o.o from Poland,.Contact details: available at hello@bidda.io.
            Personal data is processed for the purpose of providing the
            newsletter, commercial purposes and in order to grant tokens,
            pursuant to Article 6(1)(a) of Regulation (EU)2016/679 of the
            European Parliament and of the Council (FYROM) and is not
            transferred to other recipients, international organisation or third
            countries. Personal data shall be stored for the duration of
            subscriptions. The applicant has the right to access, rectify and
            lodge a complaint with the President of the Office for Personal Data
            Protection. The provision of personal data is voluntary, it does not
            result from the regulations, and failure to provide it will result
            in the impossibility of ordering. No automated decisions are made on
            the basis of personal data, including profiling.
          </p>
          <p>
            9. <strong>Disclosure of Information</strong>
          </p>
          <p tw="text-sm">
            We may release the Information concerning your use of the Site:
          </p>
          <p tw="pb-4 text-sm">
            a) To provide you with the services and functionality of the Site;
            <br />
            b) In case it is set out in the Terms of Use;
            <br />
            c) When we believe in good faith that such release is appropriate to
            comply with the applicable law, regulation, legal process or
            governmental request;
            <br />
            d) To enforce this Policy and/or the Terms of Use, or any agreement
            between you and us;
            <br />
            e) To detect, prevent, or otherwise address fraud, security or
            technical issues;
            <br />
            f) To protect against abusive or unlawful use of the Site;
            <br />
            g) To protect the rights, safety, or property of users, or any other
            third parties;
            <br />
            h) In other ways described in this Policy and the Terms of Use;
          </p>
          <p>
            10. <strong>With Whom We Share Personal Data</strong>
          </p>
          <p tw="pb-4 text-sm">
            We may share Personal Data only under any of the circumstances
            mentioned in this Policy and/or the Terms of Use. We will not share
            any Personal Data provided by you except with service providers
            under contract who help with our business operations, such as
            providers of marketing automation, information management and
            analytics services and products.
          </p>
          <p>
            11. <strong>Business or Asset Transfer</strong>
          </p>
          <p tw="pb-4 text-sm">
            In the event that we, a line of our business, or substantially all
            our assets are transferred, sold or merged with another business,
            Information may be one of the transferred assets.
          </p>
          <p>
            12. <strong>Security of Personal Data</strong>
          </p>
          <p tw="text-sm">
            We will take commercially reasonable precautions to protect Personal
            Data from loss, misuse and unauthorized access, disclosure,
            alteration and destruction. We process allInformation using
            industry-standard techniques. We implement the following
            administrative, technical, and physical measures for the protection
            of Personal Data:
          </p>
          <p tw="pb-4 text-sm">
            a) Administrative measures. Access to your Personal Data is limited
            to authorized personnel who have a legitimate need to access it
            based on their job descriptions. In case third-party contractors
            process Personal Data on our behalf, similar requirements are
            imposed.
            <br />
            b) Technical measures. Personal Data is transmitted in encrypted
            format usingSSL technology. We use up-to-date firewall protection
            and high-quality antivirus and anti-malware software.
            <br />
            c) Physical measures. We store Personal Data on our own servers, no
            third party can have access to this data.
          </p>
          <p tw="pb-4 text-sm">
            However, we do not guarantee or warrant that such techniques will
            prevent unauthorized access to Information, including Personal Data
            that we store.
          </p>
          <p>
            13. <strong>Modifications</strong>
          </p>
          <p tw="pb-4 text-sm">
            We may modify or revise this Policy at any time by posting the
            amended Policy on the Site. Please check the most current Policy.
          </p>
          <p>
            14. <strong>Changing or Updating Your Personal Data</strong>
          </p>
          <p tw="pb-4 text-sm">
            We rely on you to advise us about any changes to your Personal Data.
            Please contact us as soon as possible if there are any changes to
            your Personal Data or if you believe thePersonal Data we hold about
            you is not accurate, complete or up-to-date so that we can update
            our records accordingly.
          </p>
          <p>
            15. <strong>Privacy Related Inquiries</strong>
          </p>
          <p tw="text-sm">
            If, for any reason, you are concerned with the way that we may be
            using your PersonalData, you have questions about the privacy
            aspects of the Site, please, contact us at: hello@bidda.com.
          </p>
        </p>
      </Container>
      <div tw="bg-dark mt-20 px-5">
        <Footer />
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
